import BasicLayout from "main/layouts/BasicLayout/BasicLayout";

export default function AdminUpdatesPage() {
  // Stryker disable all : placeholder for future implementation
  return (
    <BasicLayout>
      <h1>Updates page not yet implemented</h1>
    </BasicLayout>
  );
}
